// action types
export const SELECT_CLIENT = "selectClient";
export const UNSELECT_CLIENT = "unselectClient";

export const SELECT_FISCAL_YEAR = "selectFiscalYear";
export const UNSELECT_FISCAL_YEAR = "unselectFiscalYear";

// mutation types
export const SET_CLIENT = "setClient";
export const SET_FISCAL_YEAR = "setFiscalYear";

export default {
  state: {
    client: null,
    fiscalYear: null
  },
  getters: {
    /**
     * Get current selected client
     * @param state
     * @returns {*}
     */
    selectedClient(state) {
      return state.client;
    },

    /**
     * Get current selected fiscal year
     * @param state
     * @returns {*}
     */
    selectedFiscalYear(state) {
      return state.fiscalYear;
    }
  },
  actions: {
    /**
     * Select client
     * @param state
     * @param payload
     */
    [SELECT_CLIENT](state, payload) {
      state.commit(SET_CLIENT, payload);
    },

    /**
     * Unselect client
     * @param state
     * @param payload
     */
    [UNSELECT_CLIENT](state) {
      state.commit(SET_CLIENT, null);
    },

    /**
     * Select fiscal year
     * @param state
     * @param payload
     */
    [SELECT_FISCAL_YEAR](state, payload) {
      state.commit(SET_FISCAL_YEAR, payload);
    },

    /**
     * Unselect client
     * @param state
     * @param payload
     */
    [UNSELECT_FISCAL_YEAR](state) {
      state.commit(SET_FISCAL_YEAR, null);
    }
  },
  mutations: {
    [SET_CLIENT](state, payload) {
      state.client = payload;
    },
    [SET_FISCAL_YEAR](state, payload) {
      state.fiscalYear = payload;
    }
  }
};
