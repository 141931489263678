/**
 * A service for managing promotions and their deadlines.
 */

import moment from "moment-timezone";

const PromotionService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  /**
   * Finds and assigns the closest deadlines to each promotion item based on the current date.
   * @param {Array} items - Array of promotion items, each containing promotion details and deadlines.
   * @returns {Array} The modified array of items with assigned closest deadline dates.
   */
  findAndAssignClosestDates(items) {
    return items.map(item => {
      const today = moment();
      const { promotionDeadlines } = item.promotion;

      let closestFutureEffectiveDeadline = null;

      if (promotionDeadlines && promotionDeadlines.length) {
        // Find the closest effectiveDate from the current quarter
        closestFutureEffectiveDeadline = promotionDeadlines.reduce((closest, current) => {
          const currentEffectiveDate = current.effectiveDate ? moment(current.effectiveDate) : null;
          const currentExpirationDate = current.expirationDate ? moment(current.expirationDate) : null;
          const currentSubmissionDeadlineDate = current.submissionDeadlineDate
            ? moment(current.submissionDeadlineDate)
            : null;

          const isWithinCurrentQuarter = currentEffectiveDate
            ? currentEffectiveDate.isSameOrBefore(today) &&
              (!currentExpirationDate || currentExpirationDate.isSameOrAfter(today))
            : currentExpirationDate && currentExpirationDate.isSameOrAfter(today);

          if (isWithinCurrentQuarter || currentSubmissionDeadlineDate) {
            if (!closest || (currentEffectiveDate && currentEffectiveDate.isAfter(moment(closest.effectiveDate)))) {
              return current;
            }
          }
          return closest;
        }, null);

        // If no valid current quarter deadline found, fallback to deadlines with only submissionDeadlineDate
        if (!closestFutureEffectiveDeadline) {
          closestFutureEffectiveDeadline =
            promotionDeadlines.find(
              current => !current.effectiveDate && !current.expirationDate && current.submissionDeadlineDate
            ) || null;
        }

        // Assign corresponding dates for the closest future effectiveDate or fallback
        if (closestFutureEffectiveDeadline) {
          item.closestSubmittableEffectiveDate = closestFutureEffectiveDeadline.effectiveDate || null;
          item.closestSubmittableExpirationDate = closestFutureEffectiveDeadline.expirationDate || null;
          item.closestSubmittableSubmissionDeadlineDate = closestFutureEffectiveDeadline.submissionDeadlineDate || null;
        }
      }

      return item;
    });
  },
  /**
   * Finds the latest submission deadline from a set of promotion deadlines.
   * @param {Array} promotionDeadlines - An array of deadlines from a promotion.
   * @returns {moment.Moment|null} The latest submission deadline date or null if no deadlines exist.
   */
  findLatestSubmissionDeadline(promotionDeadlines) {
    if (promotionDeadlines && promotionDeadlines.length > 0) {
      return promotionDeadlines.reduce((latest, deadline) => {
        const currentDeadlineDate = moment(deadline.submissionDeadlineDate);
        return currentDeadlineDate.isAfter(latest) ? currentDeadlineDate : latest;
      }, moment(0)); // Start with the earliest date possible
    }
    return null; // If no deadlines are found
  },

  isPromotionContestBased(promotion) {
    const { contest, promotionType } = promotion;
    return contest && promotionType.allowClaimsWithNoPayouts;
  }
};

export default PromotionService;
