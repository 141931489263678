import moment from "moment-timezone";

const ClientService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  /**
   *
   * @param {Object} client The Client object, usually gotten with selectedClient
   * @param {number} year Year in format YYYY
   * @returns
   */
  getFiscalQuartersByYear(client, year) {
    {
      let fiscalYear = this.getFiscalYearByYear(client, year);
      let start = fiscalYear.fiscalYearStart;
      let fiscalQuarters = [];
      for (let i = 0; i <= 3; i++) {
        let quarter = {
          name: "Q" + (i + 1),
          number: i + 1,
          start: moment
            .tz(start, client.timezone)
            .add(i * 3, "M")
            .toISOString(),
          end: moment
            .tz(start, client.timezone)
            .add(3 * (i + 1), "M")
            .add(-1, "seconds")
            .toISOString()
        };
        fiscalQuarters.push(quarter);
      }
      return fiscalQuarters;
    }
  },

  getFiscalHalvesByYear(client, year) {
    {
      let fiscalYear = this.getFiscalYearByYear(client, year);
      let start = fiscalYear.fiscalYearStart;
      let fiscalHalves = [];
      for (let i = 0; i <= 1; i++) {
        let halve = {
          name: "H" + (i + 1),
          number: i + 1,
          start: moment
            .tz(start, client.timezone)
            .add(i * 6, "M")
            .toISOString(),
          end: moment
            .tz(start, client.timezone)
            .add(6 * (i + 1), "M")
            .add(-1, "seconds")
            .toISOString()
        };
        fiscalHalves.push(halve);
      }
      return fiscalHalves;
    }
  },

  /**
   *
   * @param {Object} client The Client object
   * @param {string} date String UTC date
   * @returns Fiscal Quarter object
   */
  getFiscalQuarterByDate(client, date) {
    let fiscalYearObj = this.getFiscalYearByDate(client, date);
    let start = fiscalYearObj.fiscalYearStart;
    let end = moment
      .tz(start, client.timezone)
      .add(3, "M")
      .toISOString();
    let i = 0;
    while (!(moment(date).isSameOrAfter(start) && moment(date).isSameOrBefore(end))) {
      i++;
      start = moment
        .tz(start, client.timezone)
        .add(3, "M")
        .toISOString();
      end = moment
        .tz(start, client.timezone)
        .add(3, "M")
        .add(-1, "seconds")
        .toISOString();
    }
    let quarter = {
      name: "Q" + (i + 1),
      number: i + 1,
      start: start,
      end: end
    };

    return quarter;
  },

  getFiscalHalveByDate(client, date) {
    let fiscalYearObj = this.getFiscalYearByDate(client, date);
    let start = fiscalYearObj.fiscalYearStart;
    let end = moment
      .tz(start, client.timezone)
      .add(6, "M")
      .toISOString();
    let i = 0;
    while (!(moment(date).isSameOrAfter(start) && moment(date).isSameOrBefore(end))) {
      i++;
      start = moment
        .tz(start, client.timezone)
        .add(6, "M")
        .toISOString();
      end = moment
        .tz(start, client.timezone)
        .add(6, "M")
        .add(-1, "seconds")
        .toISOString();
    }
    let halve = {
      name: "H" + (i + 1),
      number: i + 1,
      start: start,
      end: end
    };

    return halve;
  },

  /**
   *
   * @param {Object} client The Client object, usually gotten with selectedClient
   * @param {string} date String UTC date
   * @returns Fiscal Year object
   */
  getFiscalYearByDate(client, date) {
    let year = parseInt(moment(date).year());
    let obj = { year: year, month: client.fiscalMonth - 1, day: client.fiscalDay };
    let fiscalYearStartMoment = moment.tz(obj, client.timezone);
    if (fiscalYearStartMoment.isAfter(moment(date))) {
      year--;
    }
    return this.getFiscalYearByYear(client, year);
  },

  /**
   *
   * @param {Object} client The Client object, usually gotten with selectedClient
   * @returns The current Fiscal Quarter object
   */
  getCurrentFiscalQuarter(client) {
    return this.getFiscalQuarterByDate(client, moment().format("YYYY-MM-DDT00:00:00.000Z"));
  },

  getCurrentFiscalHalve(client) {
    return this.getFiscalHalveByDate(client, moment().format("YYYY-MM-DDT00:00:00.000Z"));
  },

  /**
   *
   * @returns The current Fiscal Year object
   */
  getCurrentFiscalYear(client) {
    return this.getFiscalYearByDate(client, moment().format("YYYY-MM-DDT00:00:00.000Z"));
  },

  /**
   * Returns a Fiscal Object containing strings for the year, and the start and end of the fiscal year.
   * @param {Object} client - The Client object usually gotten with selectedClient
   * @param {number} year - A year in the format YYYY
   */
  getFiscalYearByYear(client, year) {
    let obj = { year: year, month: client.fiscalMonth - 1, day: client.fiscalDay };
    let fiscalYearStartMoment = moment.tz(obj, client.timezone);
    let fiscalYearStart = fiscalYearStartMoment.toISOString();
    let fiscalYearEnd = fiscalYearStartMoment
      .add(1, "years")
      .add(-1, "seconds")
      .toISOString();

    let fiscalYear = {
      fiscalYear: year,
      fiscalYearStart: fiscalYearStart,
      fiscalYearEnd: fiscalYearEnd
    };

    return fiscalYear;
  },

  generateDeadlines(client, start, end, generationType, submissionDeadlinesOffset = 0, dayOfMonthOverride) {
    let deadlines = [];

    let partsGenerationFunction = null;
    switch (generationType) {
      case "Quarters":
        partsGenerationFunction = (a, b) => this.getFiscalQuartersByYear(a, b);
        break;
      case "Halves":
        partsGenerationFunction = (a, b) => this.getFiscalHalvesByYear(a, b);
        break;
    }
    let from = moment(start);
    let to = moment(end);
    let firstFiscalYear = this.getFiscalYearByDate(client, start);
    let lastFiscalYear = this.getFiscalYearByDate(client, end);
    let flag = 0;
    for (let i = firstFiscalYear.fiscalYear; i <= lastFiscalYear.fiscalYear; i++) {
      let parts = partsGenerationFunction(client, i);
      for (let part of parts) {
        let newDeadline = null;
        if (moment(part.start).isSameOrBefore(from) && moment(part.end).isSameOrAfter(from)) {
          if (moment(part.end).isSameOrBefore(to)) {
            flag = 1;
            newDeadline = {
              effectiveDate: from.toISOString(),
              expirationDate: moment(part.end).toISOString()
            };
          } else {
            newDeadline = {
              effectiveDate: from.toISOString(),
              expirationDate: to.toISOString()
            };
          }
        } else if (moment(part.start).isSameOrBefore(to) && moment(part.end).isSameOrAfter(to)) {
          flag = 2;
          newDeadline = {
            effectiveDate: moment(part.start).toISOString(),
            expirationDate: to.toISOString()
          };
        } else if (flag == 1) {
          newDeadline = {
            effectiveDate: moment(part.start).toISOString(),
            expirationDate: moment(part.end).toISOString()
          };
        }
        if (newDeadline) {
          if (!dayOfMonthOverride || dayOfMonthOverride == "") {
            newDeadline.submissionDeadlineDate = moment
              .tz(newDeadline.expirationDate, client.timezone)
              .add(submissionDeadlinesOffset, "days")
              .toISOString();
          } else {
            let finalSubmissionDeadline = moment
              .tz(newDeadline.expirationDate, client.timezone)
              .add(submissionDeadlinesOffset, "days");
            while (finalSubmissionDeadline.date() != dayOfMonthOverride) {
              finalSubmissionDeadline.add(1, "days");
            }
            newDeadline.submissionDeadlineDate = finalSubmissionDeadline.toISOString();
          }
          deadlines.push(newDeadline);
        }
      }
    }

    return deadlines;
  }
};

export default ClientService;
