/**
 * Service to check Sony Program
 */

import ApiService from "@/gapp-components/services/api.service";

let instanceRouter = null;
const SonyProgramService = {
  init(router) {
    instanceRouter = router;
  },

  getProgramKeyByDomain() {
    const host = window.location.host;
    let programKey = null;
    switch (host) {
      case process.env.VUE_APP_PROGRAM_CPR_US_DOMAIN:
      case process.env.VUE_APP_PROGRAM_CPR_CA_DOMAIN:
        programKey = process.env.VUE_APP_PROGRAM_CPR_KEY;
        break;
      case process.env.VUE_APP_PROGRAM_CCPR_US_DOMAIN:
      case process.env.VUE_APP_PROGRAM_CCPR_CA_DOMAIN:
        programKey = process.env.VUE_APP_PROGRAM_CCPR_KEY;
        break;
      case process.env.VUE_APP_PROGRAM_EUR_US_DOMAIN:
      case process.env.VUE_APP_PROGRAM_EUR_CA_DOMAIN:
        programKey = process.env.VUE_APP_PROGRAM_EUR_KEY;
        break;
      case process.env.VUE_APP_PROGRAM_CEUR_US_DOMAIN:
      case process.env.VUE_APP_PROGRAM_CEUR_CA_DOMAIN:
      case process.env.VUE_APP_PROGRAM_CEUR_US_LANDING_DOMAIN:
      case process.env.VUE_APP_PROGRAM_CEUR_CA_LANDING_DOMAIN:
        programKey = process.env.VUE_APP_PROGRAM_CEUR_KEY;
        break;
      case process.env.VUE_APP_PROGRAM_EVE_US_DOMAIN:
        programKey = process.env.VUE_APP_PROGRAM_EVE_KEY;
        break;
      default:
        programKey = process.env.VUE_APP_PROGRAM_EUR_KEY;
        break;
    }
    return programKey;
  },

  isCPR() {
    let program = instanceRouter.app.$store.getters.selectedProgram;
    return program && program.programKey == process.env.VUE_APP_PROGRAM_CPR_KEY;
  },

  isCCPR() {
    let program = instanceRouter.app.$store.getters.selectedProgram;
    return program && program.programKey == process.env.VUE_APP_PROGRAM_CCPR_KEY;
  },

  isChannelPartnerRebates() {
    return this.isCCPR() || this.isCPR();
  },

  isEndUserRebates() {
    return this.isEUR() || this.isCEUR();
  },

  isEUR() {
    let program = instanceRouter.app.$store.getters.selectedProgram;
    return program && program.programKey == process.env.VUE_APP_PROGRAM_EUR_KEY;
  },

  isCEUR() {
    let program = instanceRouter.app.$store.getters.selectedProgram;
    return program && program.programKey == process.env.VUE_APP_PROGRAM_CEUR_KEY;
  },

  isEVE() {
    let program = instanceRouter.app.$store.getters.selectedProgram;
    return program && program.programKey == process.env.VUE_APP_PROGRAM_EVE_KEY;
  },

  isConsumer() {
    return this.isCCPR() || this.isCEUR();
  },

  isProfessional() {
    return this.isCPR() || this.isEUR();
  },
  isCaDomain() {
    const host = window.location.host;

    if (
      host == process.env.VUE_APP_PROGRAM_CPR_CA_DOMAIN ||
      host == process.env.VUE_APP_PROGRAM_CCPR_CA_DOMAIN ||
      host == process.env.VUE_APP_PROGRAM_EUR_CA_DOMAIN ||
      host == process.env.VUE_APP_PROGRAM_CEUR_CA_DOMAIN ||
      host == process.env.VUE_APP_PROGRAM_CEUR_CA_LANDING_DOMAIN
    ) {
      return true;
    }

    return false;
  },
  isUsDomain() {
    const host = window.location.host;
    if (
      host == process.env.VUE_APP_PROGRAM_CPR_US_DOMAIN ||
      host == process.env.VUE_APP_PROGRAM_CCPR_US_DOMAIN ||
      host == process.env.VUE_APP_PROGRAM_EUR_US_DOMAIN ||
      host == process.env.VUE_APP_PROGRAM_CEUR_US_DOMAIN ||
      host == process.env.VUE_APP_PROGRAM_CEUR_US_LANDING_DOMAIN ||
      host == process.env.VUE_APP_PROGRAM_EVE_US_DOMAIN
    ) {
      return true;
    }

    return false;
  },

  findActiveRSAPromotion() {
    let promotionFilters = {
      isSubmissionsEffective: true,
      invitationOnly: false
    };

    return ApiService.post("/api/promotions/search/eligible", promotionFilters).then(({ data }) => {
      let promotions = data.content
        .map(item => item.promotion)
        .filter(
          promotion =>
            promotion.promotionType.promotionTypeKey === "RSA Incentive" &&
            promotion.promotionKey.startsWith("SCPR") &&
            promotion.contest
        );
      const active = promotions.length > 0 ? promotions[0] : null;

      return Promise.resolve(active);
    });
  },

  async getJobLastRunDateByJobKey(jobKey) {
    const filters = { jobKey: jobKey };
    const itemsPerPage = 1;
    const page = 0;
    const sortBy = "expirationDate";
    const sortDesc = "DESC";
    const apiUrl = `/api/jobRuns/search?size=${itemsPerPage}&page=${page}&sort=${sortBy},${sortDesc}`;

    const { data: response } = await ApiService.post(apiUrl, filters);
    let jobRuns = response.content;

    if (!jobRuns || jobRuns.length === 0) {
      return null;
    }

    return jobRuns[0].expirationDate;
  },

  participantNeedsPreferredAwardVehicle() {
    let participant = instanceRouter.app.$store.getters.selectedParticipant;
    let participantType = instanceRouter.app.$store.getters.selectedParticipant.participantType;

    return !participant.preferredAwardVehicle && participantType.allowPreferredAwardVehicle;
  }
};

export default SonyProgramService;
