import ApiService from "./api.service";

const OcrService = {
  store: null,
  ocrAttempts: 0,
  ocrMaxAttempts: 36,
  ocrScanCancel: false,

  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  cancelOcrScan() {
    this.ocrScanCancel = true;
  },

  isOcrScanCancelled() {
    return this.ocrScanCancel;
  },

  async invoiceScan(promotionId, uploadId, isPublic, publicToken) {
    this.ocrScanCancel = false;
    let url = "/api/ocr/invoiceScan/" + promotionId + "/" + uploadId;
    if (isPublic) {
      url += "/public?publicToken=" + publicToken;
    }
    this.ocrAttempts = 0;
    return await ApiService.get(url).then(({ data }) => {
      return this.checkInvoiceScan(data, promotionId, uploadId, isPublic, publicToken);
    });
  },

  async checkInvoiceScan(ocrResult, promotionId, uploadId, isPublic, publicToken) {
    let url = "/api/ocr/checkInvoiceScan/" + promotionId + "/" + uploadId;
    if (isPublic) {
      url += "/public?publicToken=" + publicToken;
    }
    if (ocrResult.status === "READY" && this.ocrAttempts < this.ocrMaxAttempts) {
      return ocrResult;
    } else if (ocrResult.status === "PENDING" && this.ocrAttempts < this.ocrMaxAttempts) {
      this.ocrAttempts++;

      await new Promise(resolve =>
        setTimeout(() => {
          resolve();
        }, 5000)
      );

      if (!this.ocrScanCancel) {
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error("Operation timed out"));
          }, 5000); // 5 seconds timeout
        });

        return Promise.race([ApiService.get(url), timeoutPromise])
          .then(({ data }) => {
            return this.checkInvoiceScan(data, promotionId, uploadId, isPublic, publicToken);
          })
          .catch(error => {
            if (error.message === "Operation timed out") {
              return this.checkInvoiceScan(ocrResult, promotionId, uploadId, isPublic, publicToken);
            }
            return Promise.reject(error);
          });
      }
    } else if (this.ocrAttempts === this.ocrMaxAttempts) {
      return Promise.reject("Reached max attempts.");
    } else {
      return Promise.reject("OCR Error: " + JSON.stringify(ocrResult));
    }
  }
};

export default OcrService;
