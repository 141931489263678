const ID_TOKEN_KEY = "id_token";

const JwtService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  },

  saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  },

  destroyToken() {
    window.localStorage.removeItem(ID_TOKEN_KEY);
  }
};

export default JwtService;
