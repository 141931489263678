import { SELECT_COUNTRY, SELECT_LOCALE, SELECT_LOCALE_TRANSLATION } from "../store/locale.module";
import ApiService from "./api.service";

let storage = new Map();

const I18nService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  determine(forceCountry = null) {
    let selectedProgram = this.store.getters.selectedProgram;
    let selectedProgramGroup = selectedProgram ? selectedProgram.programGroup : null;
    let selectedLocale = this.store.getters.selectedLocale;
    let selectedLocaleTranslation = this.store.getters.selectedLocaleTranslation;

    let promise = null;
    let sameLocaleAndProgramGroup =
      selectedLocale != null &&
      selectedProgramGroup != null &&
      selectedProgramGroup.id == selectedLocale.programGroupId;
    // Make sure the selected locale is of the current program group
    if (sameLocaleAndProgramGroup) {
      promise = Promise.resolve(selectedLocale);
    } else {
      promise = this.fetchLocale(forceCountry);
    }
    return promise
      .then(locale => {
        selectedLocale = locale;
        if (selectedLocaleTranslation && selectedLocaleTranslation.length > 0 && sameLocaleAndProgramGroup) {
          return Promise.resolve(selectedLocale);
        } else {
          return this.fetchLocaleTranslations(selectedLocale).then(() => {
            return Promise.resolve(selectedLocale);
          });
        }
      })
      .then(locale => {
        if (locale) {
          return this.store.dispatch(SELECT_LOCALE, locale).then(() => {
            return Promise.resolve(locale);
          });
        } else {
          return Promise.reject("No locale could be determined");
        }
      });
  },
  fetchLocale(forceCountry) {
    let browserLocale = window.navigator.userLanguage || window.navigator.language;
    let parts = browserLocale.split("-");
    if (parts.length != 2) {
      return Promise.reject("Unable to determine locale from browser");
    }
    let browserLanguage = parts[0];
    let browserCountry = forceCountry ? forceCountry : parts[1];

    let selectedProgram = this.store.getters.selectedProgram;
    if (selectedProgram && selectedProgram.id) {
      return this.fetchSelectedProgramLocale(browserLanguage, browserCountry);
    } else {
      return this.fetchSelectedProgramGroupLocale(browserLanguage, browserCountry);
    }
  },
  fetchSelectedProgramLocale(language, country) {
    return ApiService.get("/api/locales").then(({ data }) => {
      let locales = data;
      let defaultLocale = data.length > 0 ? data[0] : null;
      for (let i = 0; i < locales.length; i++) {
        let locale = locales[i];
        if (locale.languageType.name == language && locale.countryType.isoAlpha2 == country) {
          return Promise.resolve(locale);
        }
      }
      return Promise.resolve(defaultLocale);
    });
  },
  fetchSelectedProgramGroupLocale(language, country) {
    return ApiService.get("/api/locales/defaultLocale/" + language + "-" + country + "/public").then(({ data }) => {
      return Promise.resolve(data);
    });
  },
  fetchLocaleTranslations(locale) {
    return ApiService.get("/api/types/address/countries/" + locale.countryType.name)
      .then(({ data }) => {
        return this.store.dispatch(SELECT_COUNTRY, data);
      })
      .then(() => {
        // Only fetch translations if we have a selected program
        if (this.store.getters.selectedProgram) {
          return ApiService.post("/api/localeTranslations/search", { locale: { id: locale.id } }).then(({ data }) => {
            return data.content;
          });
        } else {
          return Promise.resolve([]);
        }
      })
      .then(localeTranslations => {
        return this.store.dispatch(SELECT_LOCALE_TRANSLATION, localeTranslations);
      });
  },
  translate(key) {
    let localeTranslation = this.store.getters.selectedLocaleTranslation;
    if (localeTranslation) {
      let result = localeTranslation.find(o => o.key === key);
      if (result) {
        return result.value;
      } else {
        let locale_code =
          this.store && this.store.getters.selectedLocale ? this.store.getters.selectedLocale.code : null;
        let locale_codes = storage.get(key);
        if (!locale_codes) {
          locale_codes = [];
        }
        if (!locale_codes.includes(locale_code)) {
          locale_codes.push(locale_code);
        }
        storage.set(key, locale_codes);
      }
    }
    return key;
  },
  storage() {
    return storage;
  },
  clearStorage() {
    storage = new Map();
  }
};

export default I18nService;
