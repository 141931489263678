export const defaultCountry = {
  name: "USA",
  description: "United States",
  postalCodeMask: ["#####"],
  postalCodeRegex: "^\\d{5}$",
  postalCodeName: "Zip Code",
  phoneMask: ["(###) ###-####"],
  phoneRegex: "^\\(\\d{3}\\) \\d{3}\\-\\d{4}$",
  organizationTinName: "Federal Employer Identification Number (FEIN)",
  organizationTinMask: ["##-#######"],
  organizationTinRegex: "^\\d{2}-\\d{7}$",
  participantTinName: "Social Security Number (SSN)",
  participantTinMask: ["###-##-####"],
  participantTinRegex: "\\d{3}-\\d{2}-\\d{4}",
  locale: "en_US",
  isoAlpha2: "US",
  regionName: "State",
  currencyName: "USD",
  localeLanguage: "en",
  localeCountry: "US",
  localeLanguageIsoAlpha3: "eng"
};

const defaultLanguageType = {
  name: "en",
  description: "English"
};

export const defaultLocale = {
  languageType: defaultLanguageType,
  countryType: defaultCountry,
  code: "en_US"
};
export const defaultLocaleTranslation = [
  {
    locale: {
      languageType: defaultLanguageType,
      countryType: defaultCountry,
      code: "en_US"
    }
  }
];
