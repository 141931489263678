import ApiService from "./api.service";

const ContentService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  async refreshContent() {
    let { data } = await ApiService.post("/api/contents/search", { isActive: true });
    let contents = data.content.reduce((returnValue, content) => {
      returnValue[content.contentKey] = content.body;
      return returnValue;
    }, {});

    this.store.dispatch("updateContents", contents);
  }
};

export default ContentService;
