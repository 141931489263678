const routesAdmin = {
  routes() {
    return [
      {
        path: "/admin/salesnews/:id",
        name: "salesnews",
        component: () => import("./views/admin/SalesNews.vue"),
        meta: { participantTypes: ["100"] }
      },
      {
        path: "/admin/salesnewsLibrary",
        name: "salesnewsLibrary",
        component: () => import("./views/admin/SalesNewsLibrary.vue"),
        meta: { participantTypes: ["100"] }
      },
      {
        path: "/admin/claimAuditManagement",
        name: "cprClaimAuditManagement",
        component: () => import("./views/admin/ClaimAuditManagement.vue"),
        meta: { participantTypes: ["100"] }
      },
      {
        path: "/admin/claimPaymentManagement",
        name: "cprClaimPaymentManagement",
        component: () => import("./views/admin/ClaimPaymentManagement.vue"),
        meta: { participantTypes: ["100"] }
      },
      {
        path: "/admin/closedClaims",
        name: "cprClosedClaims",
        component: () => import("./views/admin/ClosedClaims.vue"),
        meta: { participantTypes: ["100"] }
      },
      {
        path: "/admin/claimManagement",
        name: "cprClaimDetailManagement",
        component: () => import("./views/admin/claim/ClaimDetailManagement.vue"),
        meta: { participantTypes: ["100"] }
      },
      {
        path: "/admin/myCompanyManagement",
        name: "cprMyCompanyManagement",
        component: () => import("./views/admin/MyCompanyManagement.vue"),
        meta: { participantTypes: ["500", "510", "600", "610", "700", "710", "800", "810", "900", "910", "930"] }
      },
      {
        path: "/admin/companyManagement",
        name: "cprCompanyManagement",
        component: () => import("./views/admin/CompanyManagement.vue"),
        meta: { participantTypes: ["100"] }
      },
      {
        path: "/admin/registrationManagement",
        name: "cprRegistrationManagement",
        component: () => import("./views/admin/RegistrationManagement.vue"),
        meta: { participantTypes: ["100", "400", "500", "510", "600", "610", "700", "710", "800", "810", "900", "910"] }
      },
      {
        path: "/admin/participantManagement",
        name: "cprParticipantManagement",
        component: () => import("./views/admin/ParticipantManagement.vue"),
        meta: { participantTypes: ["100"] }
      },
      {
        path: "/admin/promotionInvitation",
        name: "promotionInvitations",
        component: () => import("./views/admin/promotionInvitation/PromotionInvitations.vue")
      },
      {
        path: "/admin/promotionInvitation/:id",
        name: "promotionInvitation",
        component: () => import("./views/admin/promotionInvitation/PromotionInvitation.vue")
      },
      {
        path: "/admin/promotionInvitationManagement",
        name: "managePromotionInvitations",
        component: () => import("./views/admin/ManagePromotionInvitations.vue")
      }
    ];
  }
};

export default routesAdmin;
