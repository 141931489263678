/**
 * Service to check privileges
 */
const PrivilegeService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  /**
   * Check if current user has privilege (making sure to check if impersonating or not)
   */
  hasPrivilege(privilege) {
    if (this.store.getters.selectedParticipant && this.store.getters.selectedParticipant.user) {
      let user = this.store.getters.selectedParticipant.user;
      return this.hasPrivilegeForUser(privilege, user);
    } else {
      return false;
    }
  },

  hasPrivilegeForUser(privilegeName, user) {
    let ret = false;
    if (!privilegeName) {
      ret = true;
    } else {
      if (privilegeName && user && user.roles) {
        const found = user.roles.find(role => {
          if (role.privileges) {
            return role.privileges.find(privilege => {
              return privilege.name == privilegeName;
            });
          }
          return false;
        });
        ret = found != undefined;
      }
    }
    return ret;
  },

  isGappInternalUser() {
    if (this.store.getters.selectedParticipant && this.store.getters.selectedParticipant.user) {
      let user = this.store.getters.selectedParticipant.user;
      return this.isGappInternalUserForUser(user);
    } else {
      return false;
    }
  },

  isGappInternalUserForUser(user) {
    let ret = false;
    if (user) {
      ret = user.gappInternalUser == true;
    }
    return ret;
  },

  hasImpersonatePrivilege() {
    if (this.store.getters.selectedParticipant && this.store.getters.selectedParticipant.user) {
      let user = this.store.getters.selectedParticipant.user;
      return this.hasImpersonatePrivilegeForUser(user);
    } else {
      return false;
    }
  },

  hasImpersonatePrivilegeForUser(user) {
    let ret = false;
    if (user) {
      ret = this.hasPrivilegeForUser("PARTICIPANT_IMPERSONATE", user) || this.isGappInternalUserForUser(user);
    }
    return ret;
  },

  isImpersonating() {
    return this.store.getters.impersonationParticipant && this.store.getters.impersonationParticipant.id;
  }
};

export default PrivilegeService;
