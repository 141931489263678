<template>
  <router-view></router-view>
</template>

<style lang="scss">
@import "@/styles/variables.scss";
@import "@/styles/style.scss";
</style>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "Default",
    titleTemplate: "%s | Sony Rebate Center"
  }
};
</script>
