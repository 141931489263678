const ProgramService = {
  store: null,
  portalUrl: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
    if (opts.portalUrl) {
      this.portalUrl = opts.portalUrl;
    }
  },
  navigate(relativeUrl, blank) {
    let fullUrl = this.portalUrl + "/#/navigate";
    let data = {
      t: Date.now(),
      programKey: this.store.getters.selectedProgram.programKey,
      participantKey: this.store.getters.selectedParticipant.participantKey,
      redirect: relativeUrl
    };
    const querystring = this.encodeQueryData(data);
    const url = fullUrl + "?" + querystring;
    if (blank) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  },
  navigateClaim(id, blank) {
    return this.navigate("/admin/claims/" + id, blank);
  },
  navigateClient(id, blank) {
    return this.navigate("/admin/clients/" + id, blank);
  },
  navigateOrganization(id, blank) {
    return this.navigate("/admin/organizations/" + id, blank);
  },
  navigateParticipant(id, blank) {
    return this.navigate("/admin/participants/" + id, blank);
  },
  navigateProgram(id, blank) {
    return this.navigate("/admin/programs/" + id, blank);
  },
  navigatePromotion(id, blank) {
    return this.navigate("/admin/promotions/" + id, blank);
  },
  encodeQueryData(data) {
    const ret = [];
    for (let d in data) ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return ret.join("&");
  }
};

export default ProgramService;
