import Vue from "vue";

const ErrorService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  hasValidations(apiError) {
    if (!this.isApiErrorObject(apiError)) return null;
    return apiError.validationErrors && apiError.validationErrors.length > 0;
  },
  getValidationError(apiError, key) {
    if (!this.isApiErrorObject(apiError)) return null;
    if (!apiError.validationErrors) return null;
    return apiError.validationErrors[key];
  },
  clearValidationError(apiError, key) {
    if (!this.isApiErrorObject(apiError)) return null;
    Vue.delete(apiError.validationErrors, key);
  },
  clearAllValidationErrors(apiError) {
    if (!this.isApiErrorObject(apiError)) return null;
    Vue.set(apiError, "validationErrors", {});
  },
  clearAllErrors(apiError) {
    if (!this.isApiErrorObject(apiError)) return null;
    Vue.set(apiError, "validationErrors", {});
    Vue.set(apiError, "message", null);
  },
  addValidationError(apiError, key, value) {
    if (!this.isApiErrorObject(apiError)) return null;
    if (!apiError.validationErrors) return null;
    apiError.validationErrors[key] = value;
  },
  isApiErrorObject(object) {
    if (!object) return false;
    if (!(typeof object === "object")) return false;
    if (Object.keys(object) == 0) return false;
    return true;
  }
};
export default ErrorService;
