// action types
export const SELECT_STORE_PROGRAM = "selectStoreProgram";
export const UNSELECT_STORE_PROGRAM = "unselectStoreProgram";
export const SELECT_AVAILABLE_STORE_PROGRAMS = "selectAvailableStorePrograms";
export const UNSELECT_AVAILABLE_STORE_PROGRAMS = "unselectAvailableStorePrograms";
export const UPDATE_CART = "storeUpdateCart";
export const UPDATE_CART_COUNT = "storeUpdateCartCount";
export const SHOW_CART = "storeShowCart";
export const HIDE_CART = "storeHideCart";

// mutation types
export const SET_STORE_PROGRAM = "setStoreProgram";
export const SET_AVAILABLE_STORE_PROGRAMS = "setAvailableStorePrograms";
export const SET_CART = "setCart";
export const SET_CART_COUNT = "setCartCount";
export const SET_CART_VISIBILE = "setCartVisible";

export default {
  state: {
    storeProgram: null,
    available: [],
    cart: [],
    cartVisible: false,
    cartCount: 0
  },
  getters: {
    selectedStoreProgram(state) {
      return state.storeProgram;
    },
    availableStorePrograms(state) {
      return state.available;
    },
    storeCart(state) {
      return state.cart;
    },
    storeCartVisible(state) {
      return state.cartVisible;
    },
    storeCartCount(state) {
      return state.cartCount;
    }
  },
  actions: {
    [SELECT_STORE_PROGRAM](state, payload) {
      state.commit(SET_STORE_PROGRAM, payload);
    },
    [UNSELECT_STORE_PROGRAM](state) {
      state.commit(SET_STORE_PROGRAM, null);
    },
    [SELECT_AVAILABLE_STORE_PROGRAMS](state, payload) {
      state.commit(SET_AVAILABLE_STORE_PROGRAMS, payload);
    },
    [UNSELECT_AVAILABLE_STORE_PROGRAMS](state) {
      state.commit(SET_AVAILABLE_STORE_PROGRAMS, null);
    },
    [UPDATE_CART](context, cart) {
      context.commit(SET_CART, cart);
    },
    [UPDATE_CART_COUNT](context, cartCount) {
      context.commit(SET_CART_COUNT, cartCount);
    },
    [SHOW_CART](context) {
      context.commit(SET_CART_VISIBILE, true);
    },
    [HIDE_CART](context) {
      context.commit(SET_CART_VISIBILE, false);
    }
  },
  mutations: {
    [SET_STORE_PROGRAM](state, payload) {
      state.storeProgram = payload;
    },
    [SET_AVAILABLE_STORE_PROGRAMS](state, payload) {
      state.available = payload;
    },
    [SET_CART](state, cart) {
      state.cart = cart;
    },
    [SET_CART_COUNT](state, cartCount) {
      state.cartCount = cartCount;
    },
    [SET_CART_VISIBILE](state, cartVisible) {
      state.cartVisible = cartVisible;
    }
  }
};
