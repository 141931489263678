import VueApexCharts from "vue-apexcharts";
import VueCurrencyInput from "vue-currency-input";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueTheMask from "vue-the-mask";
import Vuetify from "../plugins/vuetify";
import * as filters from "./filters";

import ApiService from "./services/api.service";
import AuthService from "./services/auth.service";
import AwardVehicleService from "./services/awardvehicle.service";
import ChartService from "./services/chart.service";
import ClaimService from "./services/claim.service";
import ClientService from "./services/client.service";
import ContentService from "./services/content.service";
import CustomFieldService from "./services/customField.service";
import ErrorService from "./services/error.service";
import I18nService from "./services/i18n.service";
import JwtService from "./services/jwt.service";
import LocationService from "./services/location.service";
import OcrService from "./services/ocr.service";
import OrganizationService from "./services/organization.service";
import ParticipantService from "./services/participant.service";
import PortalService from "./services/portal.service";
import PrivilegeService from "./services/privilege.service";
import PromotionService from "./services/promotion.service";
import StoreProgramService from "./services/storeProgram.service";
import UtilService from "./services/util.service";

import VDataFooter from "vuetify/lib/components/VDataIterator/VDataFooter";
import store from "./store";

VDataFooter.options.props.itemsPerPageOptions.default = () => [10, 25, 50];

// test

const ComponentLibrary = {
  store,
  install(Vue, opts) {
    // filter registration
    for (const filterName in filters) {
      const filter = filters[filterName];
      Vue.filter(filter.name, filter.fn);
    }

    // plugin registration
    Vue.use(Vuetify);
    Vue.use(VueApexCharts);
    Vue.use(VueTheMask);
    Vue.use(VueCurrencyInput);

    // service registration (provide reference to 'store' for services)
    ApiService.init({
      store: store,
      baseUrl: opts.baseUrl,
      programGroupKey: opts.programGroupKey,
      clientKey: opts.clientKey,
      httpRedirects: opts.httpRedirects
    });
    AuthService.init({ store: store });
    ChartService.init({ store: store, vuetify: Vuetify });
    ClaimService.init({ store: store });
    ClientService.init({ store: store });
    CustomFieldService.init({ store: store });
    ErrorService.init({ store: store });
    JwtService.init({ store: store });
    I18nService.init({ store: store });
    LocationService.init({ store: store });
    OcrService.init({ store: store });
    OrganizationService.init({ store: store });
    ParticipantService.init({ store: store });
    PortalService.init({ store: store, portalUrl: opts.portalUrl });
    PrivilegeService.init({ store: store });
    AwardVehicleService.init({ store: store });
    StoreProgramService.init({ store: store });
    UtilService.init({ store: store });
    ContentService.init({ store: store });
    PromotionService.init({ store: store });
    Vue.prototype.$api = ApiService;
    Vue.prototype.$auth = AuthService;
    Vue.prototype.$chart = ChartService;
    Vue.prototype.$claim = ClaimService;
    Vue.prototype.$client = ClientService;
    Vue.prototype.$customField = CustomFieldService;
    Vue.prototype.$error = ErrorService;
    Vue.prototype.$jwt = JwtService;
    Vue.prototype.$i18n = I18nService;
    Vue.prototype.$location = LocationService;
    Vue.prototype.$ocr = OcrService;
    Vue.prototype.$organization = OrganizationService;
    Vue.prototype.$participant = ParticipantService;
    Vue.prototype.$portal = PortalService;
    Vue.prototype.$privilege = PrivilegeService;
    Vue.prototype.$promotion = PromotionService;
    Vue.prototype.$awardvehicle = AwardVehicleService;
    Vue.prototype.$storeProgram = StoreProgramService;
    Vue.prototype.$util = UtilService;
    Vue.prototype.$content = ContentService;
    Vue.prototype.$ci = VueCurrencyInput;

    // store registration
    Vue.prototype.$store = store;

    Vue.component("apexchart", VueApexCharts);

    if (opts.env) {
      Vue.prototype.$env = opts.env;
    }

    if (opts.env) {
      if (!opts.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_ID || opts.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_ID.length == 0) {
        console.log("Google ReCAPTCHA configuration is missing from site");
      }
      Vue.use(VueReCaptcha, {
        siteKey: opts.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_ID,
        loaderOptions: {
          useRecaptchaNet: true,
          autoHideBadge: true
        }
      });
    } else {
      console.log("Google Captcha v3 site key not set in the .env file.");
    }
  }
};

export default ComponentLibrary;
