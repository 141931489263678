// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPDATE_USER = "updateUser";

// mutation types
export const SET_USER = "setUser";

const state = {
  user: {},
  isAuthenticated: false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, user) {
    context.commit(SET_USER, user);
  },
  [LOGOUT](context) {
    context.commit(SET_USER, null);
  },
  [UPDATE_USER](context, user) {
    context.commit(SET_USER, user);
  }
};

const mutations = {
  [SET_USER](state, user) {
    state.isAuthenticated = user != null;
    state.user = user;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
