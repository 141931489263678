// action types
export const UPDATE_AVAILABLE_ADDRESSES = "updateAvailableAddresses";
export const UPDATE_CLAIM_FIELD_VALUES = "updateClaimFieldValues";
export const UPDATE_CLAIM_FIELDS = "updateClaimFields";
export const UPDATE_CLAIM_PRODUCTS = "updateClaimProducts";
export const UPDATE_TRADE_IN_PRODUCTS = "updateTradeInProducts";
export const UPDATE_CLAIM_UPLOADS = "updateClaimUploads";
export const UPDATE_DOCUMENTATION = "updateDocumentation";
export const UPDATE_FORMATTED_ZERO_SALES_PERIOD = "updateFormattedZeroSalesPeriod";
export const UPDATE_PARTICIPANT_SELECTED_TO_CLAIM_ON_BEHALF = "updateParticipantSelectedToClaimOnBehalf";
export const UPDATE_SAVE_END_USER_FOR_LATER = "updateSaveEndUserForLater";
export const UPDATE_SELECTED_END_USER = "updateSelectedEndUser";
export const UPDATE_SELECTED_ZERO_SALES_PERIOD = "updateSelectedZeroSalesPeriod";
export const UPDATE_ZERO_SALES_ITEMS = "updateZeroSalesItems";
export const UPDATE_MODE = "updateMode";
export const UPDATE_PROMOTION = "updatePromotion";
export const UPDATE_CLAIM_ID = "updateClaimId";
export const UPDATE_IS_PUBLIC = "updateIsPublic";
export const UPDATE_SELECTED_AWARD_VEHICLE = "updateSelectedAwardVehicle";
export const UPDATE_DOCUMENTATION_REQUIRED = "updateDocumentationRequired";
export const RESET_CLAIM_STATE = "resetClaimState";
export const UPDATE_BYPASS_ZERO_SALES = "updateBypassZeroSales";
export const UPDATE_IS_BUSY = "updateIsBusy";
export const UPDATE_CLAIM = "updateClaim";
export const UPDATE_OPTIN = "updateOptIn";
export const UPDATE_STRINGS = "updateStrings";
export const UPDATE_OBJECTS = "updateObjects";
export const UPDATE_FLAGS = "updateFlags";
export const UPDATE_RULES = "updateRules";
export const UPDATE_PRODUCTS_TO_REGISTER = "updateProductsToRegister";
export const UPDATE_PLAID_ACCOUNT_ID = "updatePlaidAccountId";

// mutation types
export const SET_AVAILABLE_ADDRESSES = "setAvailableAddresses";
export const SET_CLAIM_FIELD_VALUES = "setClaimFieldValues";
export const SET_CLAIM_FIELDS = "setClaimFields";
export const SET_CLAIM_PRODUCTS = "setClaimProducts";
export const SET_TRADE_IN_PRODUCTS = "setTradeInProducts";
export const SET_CLAIM_UPLOADS = "setClaimUploads";
export const SET_DOCUMENTATION = "setDocumentation";
export const SET_FORMATTED_ZERO_SALES_PERIOD = "setFormattedZeroSalesPeriod";
export const SET_PARTICIPANT_SELECTED_TO_CLAIM_ON_BEHALF = "setParticipantSelectedToClaimOnBehalf";
export const SET_SAVE_END_USER_FOR_LATER = "setSaveEndUserForLater";
export const SET_IS_PUBLIC = "setIsPublic";
export const SET_SELECTED_END_USER = "setSelectedEndUser";
export const SET_SELECTED_ZERO_SALES_PERIOD = "setSelectedZeroSalesPeriod";
export const SET_ZERO_SALES_ITEMS = "setZeroSalesItems";
export const SET_MODE = "setMode";
export const SET_PROMOTION = "setPromotion";
export const SET_CLAIM_ID = "setClaimId";
export const SET_SELECTED_AWARD_VEHICLE = "setSelectedAwardVehicle";
export const SET_DOCUMENTATION_REQUIRED = "setDocumentationRequired";
export const SET_BYPASS_ZERO_SALES = "setBypassZeroSales";
export const SET_CLAIM = "setClaim";
export const SET_IS_BUSY = "setIsBusy";
export const SET_OPTIN = "setOptIn";
export const SET_STRINGS = "setStrings";
export const SET_OBJECTS = "setObjects";
export const SET_FLAGS = "setFlags";
export const SET_RULES = "setRules";
export const SET_PRODUCTS_TO_REGISTER = "setProductsToRegister";
export const SET_PLAID_ACCOUNT_ID = "setPlaidAccountId";

export const CLEAR_CLAIM_STATE = "clearClaimState";

export default {
  state: {
    availableAddresses: [],
    claimFieldValues: [],
    claimFields: [],
    claimProducts: [],
    tradeInProducts: [],
    productsToRegister: [],
    claimUploads: { existing: [], deleted: [] },
    documentation: { existing: [], deleted: [] },
    formattedZeroSalesPeriod: null,
    participantSelectedToClaimOnBehalf: null,
    saveEndUserForLater: null,
    selectedEndUser: null,
    selectedZeroSalesPeriod: null,
    zeroSalesItems: null,
    plaidAccountId: null,
    mode: null,
    promotion: null,
    claimId: null,
    claim: null,
    documentationRequired: false,
    selectedAwardVehicle: null,
    bypassZeroSales: false,
    isPublic: false,
    isBusy: false,
    optIn: true,
    strings: {},
    objects: {},
    flags: {},
    rules: {}
  },
  getters: {
    availableAddresses(state) {
      return state.availableAddresses;
    },
    claimFieldValues(state) {
      return state.claimFieldValues;
    },
    claimFieldValue(state) {
      return claimFieldId => {
        return state.claimFieldValue[claimFieldId];
      };
    },
    claimFields(state) {
      return state.claimFields;
    },
    claimProducts(state) {
      return state.claimProducts;
    },
    tradeInProducts(state) {
      return state.tradeInProducts;
    },
    claimUploads(state) {
      return state.claimUploads;
    },
    documentation(state) {
      return state.documentation;
    },
    formattedZeroSalesPeriod(state) {
      return state.formattedZeroSalesPeriod;
    },
    participantSelectedToClaimOnBehalf(state) {
      return state.participantSelectedToClaimOnBehalf;
    },
    saveEndUserForLater(state) {
      return state.saveEndUserForLater;
    },
    selectedEndUser(state) {
      return state.selectedEndUser;
    },
    selectedZeroSalesPeriod(state) {
      return state.selectedZeroSalesPeriod;
    },
    zeroSalesItems(state) {
      return state.zeroSalesItems;
    },
    mode(state) {
      return state.mode;
    },
    promotion(state) {
      return state.promotion;
    },
    clameId(state) {
      return state.claimId;
    },
    selectedAwardVehicle(state) {
      return state.selectedAwardVehicle;
    },
    documentationRequired(state) {
      return state.documentationRequired;
    },
    bypassZeroSales(state) {
      return state.bypassZeroSales;
    },
    claim(state) {
      return state.claim;
    },
    invitationOnly(state) {
      return state.promotion && state.promotion.invitationOnly;
    },
    isPublic(state) {
      return state.isPublic;
    },
    isBusy(state) {
      return state.isBusy;
    },
    optIn(state) {
      return state.optIn;
    },
    strings(state) {
      return state.strings;
    },
    objects(state) {
      return state.objects;
    },
    flags(state) {
      return state.flags;
    },
    rules(state) {
      return state.rules;
    },
    productsToRegister(state) {
      return state.productsToRegister;
    },
    plaidAccountId(state) {
      return state.plaidAccountId;
    }
  },
  actions: {
    [UPDATE_AVAILABLE_ADDRESSES](state, payload) {
      state.commit(SET_AVAILABLE_ADDRESSES, payload);
    },
    [UPDATE_CLAIM_FIELD_VALUES](state, payload) {
      state.commit(SET_CLAIM_FIELD_VALUES, payload);
    },
    [UPDATE_CLAIM_FIELDS](state, payload) {
      state.commit(SET_CLAIM_FIELDS, payload);
    },
    [UPDATE_CLAIM_PRODUCTS](state, payload) {
      state.commit(SET_CLAIM_PRODUCTS, payload);
    },
    [UPDATE_TRADE_IN_PRODUCTS](state, payload) {
      state.commit(SET_TRADE_IN_PRODUCTS, payload);
    },
    [UPDATE_CLAIM_UPLOADS](state, payload) {
      state.commit(SET_CLAIM_UPLOADS, payload);
    },
    [UPDATE_DOCUMENTATION](state, payload) {
      state.commit(SET_DOCUMENTATION, payload);
    },
    [UPDATE_FORMATTED_ZERO_SALES_PERIOD](state, payload) {
      state.commit(SET_FORMATTED_ZERO_SALES_PERIOD, payload);
    },
    [UPDATE_PARTICIPANT_SELECTED_TO_CLAIM_ON_BEHALF](state, payload) {
      state.commit(SET_PARTICIPANT_SELECTED_TO_CLAIM_ON_BEHALF, payload);
    },
    [UPDATE_SAVE_END_USER_FOR_LATER](state, payload) {
      state.commit(SET_SAVE_END_USER_FOR_LATER, payload);
    },
    [UPDATE_SELECTED_END_USER](state, payload) {
      state.commit(SET_SELECTED_END_USER, payload);
    },
    [UPDATE_SELECTED_ZERO_SALES_PERIOD](state, payload) {
      state.commit(SET_SELECTED_ZERO_SALES_PERIOD, payload);
    },
    [UPDATE_ZERO_SALES_ITEMS](state, payload) {
      state.commit(SET_ZERO_SALES_ITEMS, payload);
    },
    [RESET_CLAIM_STATE](state, payload) {
      state.commit(CLEAR_CLAIM_STATE, payload);
    },
    [UPDATE_MODE](state, payload) {
      state.commit(SET_MODE, payload);
    },
    [UPDATE_PROMOTION](state, payload) {
      state.commit(SET_PROMOTION, payload);
    },
    [UPDATE_SELECTED_AWARD_VEHICLE](state, payload) {
      state.commit(SET_SELECTED_AWARD_VEHICLE, payload);
    },
    [UPDATE_CLAIM_ID](state, payload) {
      state.commit(SET_CLAIM_ID, payload);
    },
    [UPDATE_DOCUMENTATION_REQUIRED](state, payload) {
      state.commit(SET_DOCUMENTATION_REQUIRED, payload);
    },
    [UPDATE_BYPASS_ZERO_SALES](state, payload) {
      state.commit(SET_BYPASS_ZERO_SALES, payload);
    },
    [UPDATE_CLAIM](state, payload) {
      state.commit(SET_CLAIM, payload);
    },
    [UPDATE_IS_PUBLIC](state, payload) {
      state.commit(SET_IS_PUBLIC, payload);
    },
    [UPDATE_IS_BUSY](state, payload) {
      state.commit(SET_IS_BUSY, payload);
    },
    [UPDATE_OPTIN](state, payload) {
      state.commit(SET_OPTIN, payload);
    },
    [UPDATE_STRINGS](state, payload) {
      state.commit(SET_STRINGS, payload);
    },
    [UPDATE_OBJECTS](state, payload) {
      state.commit(SET_OBJECTS, payload);
    },
    [UPDATE_FLAGS](state, payload) {
      state.commit(SET_FLAGS, payload);
    },
    [UPDATE_RULES](state, payload) {
      state.commit(SET_RULES, payload);
    },
    [UPDATE_PRODUCTS_TO_REGISTER](state, payload) {
      state.commit(SET_PRODUCTS_TO_REGISTER, payload);
    },
    [UPDATE_PLAID_ACCOUNT_ID](state, payload) {
      state.commit(SET_PLAID_ACCOUNT_ID, payload);
    }
  },
  mutations: {
    [SET_AVAILABLE_ADDRESSES](state, payload) {
      state.availableAddresses = payload;
    },
    [SET_CLAIM_FIELD_VALUES](state, payload) {
      state.claimFieldValues = payload;
    },
    [SET_CLAIM_FIELDS](state, payload) {
      state.claimFields = payload;
    },
    [SET_CLAIM_PRODUCTS](state, payload) {
      state.claimProducts = payload;
    },
    [SET_TRADE_IN_PRODUCTS](state, payload) {
      state.tradeInProducts = payload;
    },
    [SET_CLAIM_UPLOADS](state, payload) {
      state.claimUploads = payload;
    },
    [SET_DOCUMENTATION](state, payload) {
      state.documentation = payload;
    },
    [SET_FORMATTED_ZERO_SALES_PERIOD](state, payload) {
      state.formattedZeroSalesPeriod = payload;
    },
    [SET_PARTICIPANT_SELECTED_TO_CLAIM_ON_BEHALF](state, payload) {
      state.participantSelectedToClaimOnBehalf = payload;
    },
    [SET_SAVE_END_USER_FOR_LATER](state, payload) {
      state.saveEndUserForLater = payload;
    },
    [SET_SELECTED_END_USER](state, payload) {
      state.selectedEndUser = payload;
    },
    [SET_SELECTED_ZERO_SALES_PERIOD](state, payload) {
      state.selectedZeroSalesPeriod = payload;
    },
    [SET_ZERO_SALES_ITEMS](state, payload) {
      state.zeroSalesItems = payload;
    },
    [SET_MODE](state, payload) {
      state.mode = payload;
    },
    [SET_PROMOTION](state, payload) {
      state.promotion = payload;
    },
    [SET_CLAIM_ID](state, payload) {
      state.claimId = payload;
    },
    [SET_SELECTED_AWARD_VEHICLE](state, payload) {
      state.selectedAwardVehicle = payload;
    },
    [SET_DOCUMENTATION_REQUIRED](state, payload) {
      state.documentationRequired = payload;
    },
    [SET_BYPASS_ZERO_SALES](state, payload) {
      state.bypassZeroSales = payload;
    },
    [SET_CLAIM](state, payload) {
      state.claim = payload;
    },
    [SET_IS_PUBLIC](state, payload) {
      state.isPublic = payload;
    },
    [SET_IS_BUSY](state, payload) {
      state.isBusy = payload;
    },
    [SET_OPTIN](state, payload) {
      state.optIn = payload;
    },
    [SET_STRINGS](state, payload) {
      state.strings = payload;
    },
    [SET_OBJECTS](state, payload) {
      state.objects = payload;
    },
    [SET_FLAGS](state, payload) {
      state.flags = payload;
    },
    [SET_RULES](state, payload) {
      state.rules = payload;
    },
    [SET_PRODUCTS_TO_REGISTER](state, payload) {
      state.productsToRegister = payload;
    },
    [SET_PLAID_ACCOUNT_ID](state, payload) {
      state.plaidAccountId = payload;
    },
    [CLEAR_CLAIM_STATE](state) {
      state.availableAddresses = [];
      state.claimFieldValues = [];
      state.claimFields = [];
      state.claimProducts = [];
      state.tradeInProducts = [];
      state.productsToRegister = [];
      state.claimUploads = { existing: [], deleted: [] };
      state.documentation = { existing: [], deleted: [] };
      state.formattedZeroSalesPeriod = null;
      state.participantSelectedToClaimOnBehalf = null;
      state.saveEndUserForLater = null;
      state.selectedEndUser = null;
      state.selectedZeroSalesPeriod = null;
      state.zeroSalesItems = null;
      state.mode = null;
      state.promotion = null;
      state.claimId = null;
      state.plaidAccountId = null;
      state.documentationRequired = false;
      state.selectedAwardVehicle = null;
      state.bypassZeroSales = false;
      state.isPublic = false;
      state.optIn = true;
      state.strings = {};
      state.objects = {};
      state.flags = {};
      state.rules = {};
    }
  }
};
