const ChartService = {
  store: null,
  vuetify: null,

  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
    if (opts.vuetify) {
      this.vuetify = opts.vuetify;
    }
  },

  getColors() {
    let theme = this.vuetify.preset.theme.isDark
      ? this.vuetify.userPreset.theme.themes.dark
      : this.vuetify.userPreset.theme.themes.light;
    let colors = [];
    colors.push(theme.primary);
    colors.push(theme.secondary);
    colors.push(theme.accent);
    colors.push(theme.info);
    colors.push(theme.success);
    colors.push(theme.warning);
    colors.push(theme.error);
    return colors;
  }
};

export default ChartService;
