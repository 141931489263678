import ApiService from "./api.service";

import {
  SELECT_STORE_PROGRAM,
  SELECT_AVAILABLE_STORE_PROGRAMS,
  UNSELECT_AVAILABLE_STORE_PROGRAMS,
  UPDATE_CART,
  UPDATE_CART_COUNT,
  SHOW_CART,
  HIDE_CART
} from "../store/storeProgram.module";

const StoreService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  storeSelectStoreProgram(storeProgram) {
    return this.store.dispatch(SELECT_STORE_PROGRAM, storeProgram).then(() => {
      return Promise.resolve(storeProgram);
    });
  },
  storeAvailableStorePrograms() {
    return ApiService.get("/api/storePrograms/bySelectedParticipant").then(({ data }) => {
      let storePrograms = data;
      return this.store.dispatch(SELECT_AVAILABLE_STORE_PROGRAMS, data).then(() => {
        return Promise.resolve(storePrograms);
      });
    });
  },
  clearAvailableStorePrograms() {
    return this.store.dispatch(UNSELECT_AVAILABLE_STORE_PROGRAMS);
  },
  showCart() {
    return this.store.dispatch(SHOW_CART);
  },
  hideCart() {
    return this.store.dispatch(HIDE_CART);
  },
  calculcateCartCount(cart) {
    let count = 0;
    if (cart && cart.length > 0) {
      cart.forEach(catalogProduct => {
        count += catalogProduct.quantity;
      });
    }
    return count;
  },
  addToCart(catalogProduct, quantity) {
    quantity = parseInt(quantity);
    let cart = this.store.getters.storeCart;
    let index = cart.findIndex(cartProduct => cartProduct.id == catalogProduct.id);
    if (index > -1) {
      let p = cart[index];
      quantity += p.quantity;
    }
    return this.updateCart(catalogProduct, quantity);
  },
  updateCart(catalogProduct, quantity) {
    catalogProduct.quantity = parseInt(quantity);
    let cart = this.store.getters.storeCart;
    let index = cart.findIndex(cartProduct => cartProduct.id == catalogProduct.id);
    if (index > -1) {
      cart.splice(index, 1, catalogProduct);
    } else {
      cart.push(catalogProduct);
    }
    return this.store.dispatch(UPDATE_CART, cart).then(() => {
      return this.store.dispatch(UPDATE_CART_COUNT, this.calculcateCartCount(cart));
    });
  },
  removeFromCart(catalogProduct) {
    let cart = this.store.getters.storeCart;
    let index = cart.findIndex(cartProduct => cartProduct.id == catalogProduct.id);
    if (index > -1) {
      cart.splice(index, 1);
    }
    return this.store.dispatch(UPDATE_CART, cart).then(() => {
      return this.store.dispatch(UPDATE_CART_COUNT, this.calculcateCartCount(cart));
    });
  },
  clearCart() {
    let cart = this.store.getters.storeCart;
    cart.splice(0);
    return this.store.dispatch(UPDATE_CART, cart).then(() => {
      return this.store.dispatch(UPDATE_CART_COUNT, this.calculcateCartCount(cart));
    });
  }
};

export default StoreService;
