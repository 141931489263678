const OrganizationService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  /**
   * This method determines if a participant is missing required information based on the settings configured at the Organization Type level.
   *
   * @returns boolean
   */
  isMissingRequiredInformation() {
    let isMissing = false;
    let participant = this.store.getters.selectedParticipant;
    if (participant) {
      let organization = participant.organization;
      if (organization) {
        let allowedToManageOrganization = participant.participantType.allowOrganizationManagement;
        let missingRequiredInformation = organization.missingRequiredInformation;
        let hasSource = organization.source && organization.source.trim().length > 0;
        isMissing = allowedToManageOrganization && missingRequiredInformation && !hasSource;
      }
    }
    if (isMissing) {
      console.log("Missing organization required information");
    }
    return isMissing;
  }
};

export default OrganizationService;
