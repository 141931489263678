const ParticipantService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  /**
   * Determines if a participant is missing required information based on the settings configured at
   * the Participant Type level.
   *
   * @returns boolean
   */
  isMissingRequiredInformation() {
    let participant = this.store.getters.selectedParticipant;
    let missingRequiredInformation = participant.missingRequiredInformation;
    let hasSource = participant.source && participant.source.trim().length > 0;
    let isMissing = missingRequiredInformation && !hasSource;
    if (isMissing) {
      console.log("Missing participant required information");
    }
    return isMissing;
  },

  /**
   * Determines if a participant is missing security answers.  This method is smart enough to look at the
   * configuration of the program group and based on the number of security questions required, does the
   * user have the same number of answers.
   *
   * @returns
   */
  isMissingSecurityAnswers() {
    let isMissing = false;
    let participant = this.store.getters.selectedParticipant;
    if (participant) {
      let program = participant.program;
      if (program) {
        let programGroup = program.programGroup;
        if (programGroup) {
          let amountOfSecurityQuestionsForProgramGroup = programGroup.amountOfSecurityQuestions;
          if (amountOfSecurityQuestionsForProgramGroup > 0) {
            let securityAnswerCount = this.store.getters.securityAnswersCount;
            if (securityAnswerCount && securityAnswerCount >= amountOfSecurityQuestionsForProgramGroup) {
              isMissing = false;
            } else {
              isMissing = true;
            }
          }
        }
      }
    }
    if (isMissing) {
      console.log("Missing security answers");
    }
    return isMissing;
  }
};

export default ParticipantService;
