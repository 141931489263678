import UtilService from "../services/util.service";

export default {
  name: "showActive",
  fn: objectWithEffectivity => {
    let active = UtilService.isEffective(objectWithEffectivity, null);
    if (active) {
      return "<span class='v-chip theme--light v-size--default primary'><span class='v-chip__content'>Active</span></span>";
    } else {
      return "<span class='v-chip theme--light v-size--default danger'><span class='v-chip__content'>Inactive</span></span>";
    }
  }
};
