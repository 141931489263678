import moment from "moment-timezone";

const UtilService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  parseDateClient(dateString, format, client) {
    let timezone = client && client.timezone ? client.timezone : moment.tz.guess();
    return dateString && dateString.length > 0 ? moment.tz(dateString, format, timezone) : "";
  },
  formatDateClient(utcDateString, format, client) {
    let timezone = client && client.timezone ? client.timezone : moment.tz.guess();
    return utcDateString && utcDateString.length > 0 ? moment.tz(utcDateString, timezone).format(format) : "";
  },
  formatStage(claimStage, claim) {
    let stageName = claimStage.name;
    if (claim.approved === true && claimStage.nameWhenApproved && claimStage.nameWhenApproved.trim().length > 0) {
      stageName = claimStage.nameWhenApproved;
    } else if (
      claim.rejected === true &&
      claimStage.nameWhenRejected &&
      claimStage.nameWhenRejected.trim().length > 0
    ) {
      stageName = claimStage.nameWhenRejected;
    }
    return stageName;
  },
  isEffective(objectWithEffectivity, momentDate) {
    let now = momentDate ? moment(momentDate.format("YYYY-MM-DDTHH:mm:ss.SSSSZ")) : moment();

    let active = false;
    const effectiveDate = objectWithEffectivity.effectiveDate ? moment(objectWithEffectivity.effectiveDate) : null;
    const expirationDate = objectWithEffectivity.expirationDate ? moment(objectWithEffectivity.expirationDate) : null;

    if (effectiveDate && expirationDate) {
      active = now.isBetween(effectiveDate, expirationDate, undefined, "[]") || now.isSame(effectiveDate);
    } else if (effectiveDate) {
      active = now.isSameOrAfter(effectiveDate);
    } else if (expirationDate) {
      active = now.isSameOrBefore(expirationDate);
    } else {
      active = true;
    }
    return active;
  },
  toCurrency(value) {
    let number = parseFloat(value);

    if (isNaN(number)) {
      return value;
    }
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    });
    return formatter.format(number);
  },
  toPhoneNumber(value) {
    // Filter only numbers from the input
    let cleaned = ("" + value).replace(/\D/g, "");

    // Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      // Remove the matched extension code
      // Change this to format for any country code.
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return value;
  },
  toNumber(value, decimals, program) {
    if (typeof value !== "number") {
      return value;
    }
    if (program && program.awardUnitsEnabled && program.awardUnitsDecimalPlace > -1) {
      decimals = program.awardUnitsDecimalPlace;
    }
    var formatter;
    if (decimals) {
      value = value.toFixed(decimals);
      formatter = new Intl.NumberFormat("en-US", { minimumFractionDigits: decimals });
    } else {
      formatter = new Intl.NumberFormat("en-US");
    }
    return formatter.format(value);
  },
  toPropercase(value) {
    return value.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
  getDateInClientTimezone(date) {
    let client = this.store.getters.selectedClient.timezone;
    let timezone = client && client.timezone ? client.timezone : moment.tz.guess();
    return moment.tz(date, timezone);
  }
};

export default UtilService;
