// action types
export const UPDATE_CONTENTS = "updateContents";

// mutation types
export const SET_CONTENTS = "setContents";

export default {
  state: {
    contents: []
  },
  getters: {
    contents(state) {
      return state.contents;
    }
  },
  actions: {
    [UPDATE_CONTENTS](state, payload) {
      state.commit(SET_CONTENTS, payload);
    }
  },
  mutations: {
    [SET_CONTENTS](state, payload) {
      state.contents = payload;
    }
  }
};
