import ApiService from "./api.service";

const LocationService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  parseAddressForPromotion(addressText, promotionId) {
    return ApiService.post("/api/locations/parseAddressForPromotion/public", {
      promotionId: promotionId,
      addressText: addressText
    }).then(({ data }) => {
      return data;
    });
  }
};

export default LocationService;
