const routesCommonPublic = {
  routes() {
    return [
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("./gapp-components/components/forms/ForgotPasswordForm.vue")
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("./gapp-components/components/forms/ResetPasswordForm.vue")
      },
      {
        path: "/credentialsExpired",
        name: "credentials-expired",
        component: () => import("./gapp-components/components/forms/CredentialsExpiredForm.vue")
      },
      {
        path: "/captivePage",
        name: "captivePage",
        component: () => import("./gapp-components/components/forms/CredentialsExpiredForm.vue")
      },
      {
        path: "/accessDenied",
        name: "accessDenied",
        component: () => import("./views/AccessDenied.vue")
      }
    ];
  }
};

export default routesCommonPublic;
