// action types
export const START_IMPERSONATE = "startImpersonate";
export const STOP_IMPERSONATE = "stopImpersonate";
export const SELECT_PARTICIPANT = "selectParticipant";
export const UNSELECT_PARTICIPANT = "unselectParticipant";
export const SELECT_AVAILABLE_PARTICIPANTS = "selectAvailableParticipants";
export const UNSELECT_AVAILABLE_PARTICIPANTS = "unselectAvailableParticipants";
export const SECURITY_ANSWER_COUNT = "securityAnswersCount";
export const MISSING_PROFILE_INFO = "missingProfileInfo";
export const UPDATE_CLAIM_AUDIT_FILTERS = "updateClaimAuditFilters";

// mutation types
export const SET_IMPERSONATION_PARTICIPANT = "setImpersonationParticipant";
export const SET_PARTICIPANT = "setParticipant";
export const SET_AVAILABLE_PARTICIPANTS = "setAvailableParticipants";
export const SET_SECURITY_ANSWERS = "setSecurityAnswers";
export const SET_MISSING_PROFILE_INFO = "setMissingProfileInfo";
export const SET_CLAIM_AUDIT_FILTERS = "setClaimAuditFilters";

export default {
  state: {
    participant: null,
    available: [],
    impersonationParticipant: null,
    securityAnswers: 0,
    missingProfileInfo: false,
    claimAuditFilters: []
  },
  getters: {
    /**
     * Get status about missing profile info
     * @param state
     * @returns {*}
     */
    missingProfileInfo(state) {
      return state.missingProfileInfo;
    },
    /**
     * Get security answers
     * @param state
     * @returns {*}
     */
    securityAnswersCount(state) {
      return state.securityAnswers;
    },
    /**
     * Get current selected participant
     * @param state
     * @returns {*}
     */
    selectedParticipant(state) {
      return state.participant;
    },

    /**
     * Get current impersonation participant
     * @param state
     * @returns {*}
     */
    impersonationParticipant(state) {
      return state.impersonationParticipant;
    },

    /**
     * Get available participant
     * @param state
     * @returns {*}
     */
    availableParticipants(state) {
      return state.available;
    },

    /**
     * Get claim audit filters
     * @param state
     * @returns {*}
     */
    getClaimAuditFilters(state) {
      return state.claimAuditFilters;
    }
  },
  actions: {
    /**
     * Set missing profile info
     * @param state
     * @param payload
     */
    [MISSING_PROFILE_INFO](state, payload) {
      state.commit(SET_MISSING_PROFILE_INFO, payload);
    },
    /**
     * Set security answers
     * @param state
     * @param payload
     */
    [SECURITY_ANSWER_COUNT](state, payload) {
      state.commit(SET_SECURITY_ANSWERS, payload);
    },
    /**
     * Select participant
     * @param state
     * @param payload
     */
    [SELECT_PARTICIPANT](state, payload) {
      state.commit(SET_PARTICIPANT, payload);
    },

    /**
     * UnSelect participant
     * @param state
     * @param payload
     */
    [UNSELECT_PARTICIPANT](state) {
      state.commit(SET_PARTICIPANT, null);
    },

    /**
     * Store available participant
     * @param state
     * @param payload
     */
    [SELECT_AVAILABLE_PARTICIPANTS](state, payload) {
      state.commit(SET_AVAILABLE_PARTICIPANTS, payload);
    },

    /**
     * Remove available participant
     * @param state
     * @param payload
     */
    [UNSELECT_AVAILABLE_PARTICIPANTS](state) {
      state.commit(SET_AVAILABLE_PARTICIPANTS, null);
    },

    /**
     * Start impersonating a participant
     * @param state
     * @param payload
     */
    [START_IMPERSONATE](state, payload) {
      state.commit(SET_IMPERSONATION_PARTICIPANT, state.state.participant);
      state.commit(SET_PARTICIPANT, payload);
    },

    /**
     * Stop impersonating a participant
     * @param state
     */
    [STOP_IMPERSONATE](state) {
      if (state.state.impersonationParticipant) {
        state.commit(SET_PARTICIPANT, state.state.impersonationParticipant);
        state.commit(SET_IMPERSONATION_PARTICIPANT, null);
      }
    },

    [UPDATE_CLAIM_AUDIT_FILTERS](state, payload) {
      state.commit(SET_CLAIM_AUDIT_FILTERS, payload);
    }
  },
  mutations: {
    [SET_IMPERSONATION_PARTICIPANT](state, payload) {
      state.impersonationParticipant = payload;
    },
    [SET_PARTICIPANT](state, payload) {
      state.participant = payload;
    },
    [SET_AVAILABLE_PARTICIPANTS](state, payload) {
      state.available = payload;
    },
    [SET_SECURITY_ANSWERS](state, payload) {
      state.securityAnswers = payload;
    },
    [SET_MISSING_PROFILE_INFO](state, payload) {
      state.missingProfileInfo = payload;
    },
    [SET_CLAIM_AUDIT_FILTERS](state, payload) {
      state.claimAuditFilters = payload;
    }
  }
};
