const routesCommonAuthenticated = {
  routes() {
    return [
      {
        path: "/profile",
        name: "profile",
        component: () => import("./views/common/profile/Profile.vue")
      },
      {
        path: "/missingPrincipalOwner",
        name: "missingPrincipalOwner",
        component: () => import("./views/MissingPrincipalOwner.vue")
      }
    ];
  }
};

export default routesCommonAuthenticated;
