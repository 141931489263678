import moment from "moment-timezone";

const CustomFieldService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  /**
   * Given a custom field, return the appropriate align value
   *
   * @param {*} customField
   * @returns align value
   */
  alignValue(customField) {
    let returnValue = "left";
    if (customField && customField.fieldType) {
      let fieldType = customField.fieldType;
      switch (fieldType.name) {
        case "ARRAY":
        case "DATE":
        case "DATETIME":
        case "STRING":
          break;
        case "DECIMAL":
        case "INTEGER":
          returnValue = "right";
          break;
        case "BOOLEAN":
          returnValue = "center";
          break;
        default:
          break;
      }
    }
    return returnValue;
  },

  /**
   * Given a custom field and a value, render the value accordingly based on the type of custom field
   *
   * @param {Object} customField The CustomField Object
   * @param {string} value value of the custom field
   * @returns
   */
  renderValue(customField, value, dateFormat) {
    let returnValue = value;
    if (customField && customField.fieldType) {
      let fieldType = customField.fieldType;
      switch (fieldType.name) {
        case "ARRAY":
        case "STRING":
        case "DECIMAL":
        case "INTEGER":
          break;
        case "DATE":
          if (value && value.trim().length > 0) {
            if (!dateFormat) {
              dateFormat = "MM/DD/YYYY";
            }
            returnValue = moment(value).format(dateFormat);
          }
          break;
        case "DATETIME":
          if (value && value.trim().length > 0) {
            if (!dateFormat) {
              dateFormat = "MM/DD/YYYY hh:mm a z";
            }
            returnValue = moment.tz(value, this.store.getters.selectedClient.timezone).format(dateFormat);
          }
          break;
        case "BOOLEAN":
          if (value && value.trim().toLowerCase() == "true") {
            returnValue = "Yes";
          } else {
            returnValue = "No";
          }
          break;

        default:
          break;
      }
    }

    return returnValue;
  }
};

export default CustomFieldService;
