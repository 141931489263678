import { defaultCountry, defaultLocale, defaultLocaleTranslation } from "@/gapp-components/store/defaults";

// action types
export const SELECT_COUNTRY = "selectCountry";
export const SELECT_LOCALE = "selectLocale";
export const SELECT_LOCALE_TRANSLATION = "selectLocaleTranslation";

// mutation types
export const SET_COUNTRY = "setCountry";
export const SET_LOCALE = "setLocale";
export const SET_LOCALE_TRANSLATION = "setLocaleTranslation";

export default {
  state: {
    country: null,
    locale: null,
    localeTranslation: null
  },
  getters: {
    selectedCountry(state) {
      return state.country || defaultCountry;
    },
    selectedLocale(state) {
      return state.locale || defaultLocale;
    },
    selectedLocaleTranslation(state) {
      return state.localeTranslation || defaultLocaleTranslation;
    }
  },
  actions: {
    /**
     * Select country
     * @param state
     * @param payload
     */
    [SELECT_COUNTRY](state, payload) {
      state.commit(SET_COUNTRY, payload);
    },
    /**
     * Select locale
     * @param state
     * @param payload
     */
    [SELECT_LOCALE](state, payload) {
      state.commit(SET_LOCALE, payload);
    },
    /**
     * Select translation
     * @param state
     * @param payload
     */
    [SELECT_LOCALE_TRANSLATION](state, payload) {
      state.commit(SET_LOCALE_TRANSLATION, payload);
    }
  },
  mutations: {
    [SET_COUNTRY](state, payload) {
      state.country = payload;
    },
    [SET_LOCALE](state, payload) {
      state.locale = payload;
    },
    [SET_LOCALE_TRANSLATION](state, payload) {
      state.localeTranslation = payload;
    }
  }
};
