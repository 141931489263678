import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#7ac3d3", // Pro Dark Blue - #2c3e50
        secondary: "#2c3e50", // Pro Grey Dark - #4a4a4a
        accent: "#4a4a4a", // CTA Blue Light - #31b6ee
        error: "#f08282", // Pulled from accepted text colour palette
        info: "#1b79A7", // CTA Blue Dark - #1b79A7
        success: "#31b6ee", // Pro Blue - #7ac3d3
        warning: "ffd200" // CTA Yellow - #ffd200
      },
      dark: {
        primary: "#31b6ee", // CTA Blue Light - #31b6ee
        secondary: "#ffffff", // White
        accent: "#31b6ee", // CTA Blue Light - #31b6ee
        error: "#f08282", // Pulled from accepted text colour palette
        info: "#1b79A7", // CTA Blue Dark - #1b79A7
        success: "#7ac3d3", // Pro Blue - #7ac3d3
        warning: "ffd200" // CTA Yellow - #ffd200
      }
    }
  }
});
