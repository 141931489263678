const routesReports = {
  routes() {
    return [
      {
        path: "/reports/allClaimTypesReporting",
        name: "allClaimTypesReporting",
        component: () => import("./views/reports/AllClaimTypesReporting.vue"),
        props: true
      },
      {
        path: "/reports/claimReporting",
        name: "claimReporting",
        component: () => import("./views/reports/ClaimReporting.vue")
      },
      {
        path: "/reports/customReport/:id",
        name: "cprCustomReport",
        component: () => import("./views/reports/CustomReport.vue")
      },
      {
        path: "/reports/enrollmentReporting",
        name: "enrollmentReporting",
        component: () => import("./views/reports/EnrollmentReporting.vue")
      },
      {
        path: "/reports/reportDownloads",
        name: "reportDownloads",
        component: () => import("./views/reports/ReportDownloads.vue")
      },
      {
        path: "/reports/virReport",
        name: "cprVirReport",
        component: () => import("./views/reports/VirReport.vue")
      },
      {
        path: "/reports/virReportEmail",
        name: "cprVirReportEmail",
        component: () => import("./views/reports/VirReportEmail.vue")
      }
    ];
  }
};

export default routesReports;
