// action types
export const SELECT_PROGRAM = "selectProgram";
export const UNSELECT_PROGRAM = "unselectProgram";

// mutation types
export const SET_PROGRAM = "setProgram";

export default {
  state: {
    program: null
  },
  getters: {
    /**
     * Get current selected program
     * @param state
     * @returns {*}
     */
    selectedProgram(state) {
      return state.program;
    }
  },
  actions: {
    /**
     * Select program
     * @param state
     * @param payload
     */
    [SELECT_PROGRAM](state, payload) {
      state.commit(SET_PROGRAM, payload);
    },

    /**
     * Unselect program
     * @param state
     * @param payload
     */
    [UNSELECT_PROGRAM](state) {
      state.commit(SET_PROGRAM, null);
    }
  },
  mutations: {
    [SET_PROGRAM](state, payload) {
      state.program = payload;
    }
  }
};
